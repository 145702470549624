import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 * */
export var constantRoutes = [{
  path: '/redirect',
  hidden: true,
  component: Layout,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/components/ErrorPage/404');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export var asyncRoutes = [{
  path: '/',
  redirect: '/agent',
  name: 'index',
  component: Layout,
  children: []
}, {
  path: '/agent',
  component: Layout,
  meta: {
    title: 'Agent 管理',
    icon: 'node'
  },
  redirect: {
    name: 'agent_manager'
  },
  children: [{
    path: 'status',
    name: 'agent_manager',
    component: function component() {
      return import('@/views/agent');
    },
    meta: {
      title: '节点管理',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/cloud',
  component: Layout,
  meta: {
    title: 'Cloud 管理',
    icon: 'node'
  },
  redirect: {
    name: 'cloud_manager'
  },
  children: [{
    path: 'cloud',
    name: 'cloud_manager',
    component: function component() {
      return import('@/views/cloud');
    },
    meta: {
      title: '云区域管理',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'install/:id',
    name: 'cloud_install',
    component: function component() {
      return import('@/views/cloud/install');
    },
    meta: {
      title: '安装proxy',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'detail/:id',
    name: 'cloud_detail',
    component: function component() {
      return import('@/views/cloud/detail');
    },
    meta: {
      title: '安装proxy',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/task-history',
  component: Layout,
  meta: {
    title: '执行历史',
    icon: 'node'
  },
  redirect: {
    name: 'task-history'
  },
  children: [{
    path: 'task-history',
    name: 'task-history',
    component: function component() {
      return import('@/views/task-history');
    },
    meta: {
      title: '执行历史',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;